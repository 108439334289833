import styled from "@emotion/styled";
import { Select, MenuItem } from "@mui/material";

const StyledProjectDropdown = styled(Select)`
  max-width: 80%;
  border-radius: 0;
  text-align: center;
  align-items: center;
  font-size: 20px;
  height: 2em;
  border: none;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  background-color: #FF8CD1;
  background: linear-gradient(to right bottom, #FFA3DA, #FF8CD1);
  font-family: leagueSpartan;
  padding-left: 24px;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.menu ? `230ms` : `600ms` };

  @media (max-width: 899px) {
    max-width: 100%;
  }

  @media (max-width: 520px) {
    min-width: none;
    width: 100%;
  }
`;

const StyledSelectItems = styled(MenuItem)`
  border-radius: 0;
  justify-content: center;
  align-items: center;
  background-color: #FF8CD1;
  font-family: leagueSpartan;
  font-size: 18px;

  :hover {
    background: #FFA3DA;
  }
`;

export { StyledProjectDropdown, StyledSelectItems };