import PageTitle from "./PageTitle";
import styled from "@emotion/styled";

const StyledPageTitle = styled(PageTitle)`
  display: flex;
  font-size: 4em;
  margin-bottom: 0.5em;
  font-family: leagueSpartan;
`;

export default StyledPageTitle;