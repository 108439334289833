import projectsArr from "../../projects/projectsArr";
import { StyledProjectDropdown, StyledSelectItems } from "./ProjectDropdown.styles";

const ProjectDropdown = ({ selectedProjectCat, setSelectedProjectCat, setIsMenuClicked, isMenuClicked }) => (
  // <select className={className} onChange={e => { setSelectedProjectCat(e.target.value);  setIsMenuClicked(true)}}>
  //   { projectsArr[0].map((prop, index) =>
  //     <option key={`selection-${index}`} value={prop}>{prop}</option>
  //     )
  //   }
  // </select>

  <StyledProjectDropdown
    menu={isMenuClicked.toString()}
    value={selectedProjectCat}
    onChange={e => { setSelectedProjectCat(e.target.value);  setIsMenuClicked(true)}}
    displayEmpty
    inputProps={{ 'aria-label': 'Without label' }}
    variant="standard"
    name="projectDropdown"
    disableUnderline
  >
    { projectsArr[0].map((prop, index) =>
      <StyledSelectItems
        key={`selection-${index}`}
        value={prop}
      >
        {prop}
      </StyledSelectItems>
    )}
  </StyledProjectDropdown>
);

export default ProjectDropdown;