import { StyledMenuButton, StyledHeader, StyledHamburgerButton, StyledMenuButtonsDiv } from "./Header.styles";
// import { useState } from "react";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const Header = ({ setIsMenuClicked, isHamburgerOpen, setIsHamburgerOpen }) => {
  const menuButtonsArr = [
    { name: 'Home', link: '/', bgColor: 'linear-gradient(to right bottom, #333333, #000000)', textColor: '#FFFFFF' },
    { name: 'Projects', link: '/projects', bgColor: 'linear-gradient(to right bottom, #FFA3DA, #FF8CD1)', textColor: '#000000' },
    { name: 'About', link: '/about', bgColor: 'linear-gradient(to right bottom, #FFDD86, #FFD568)', textColor: '#000000' },
    { name: 'Contact', link: '/contact', bgColor: 'linear-gradient(to right bottom, #98E0FF, #7ED8FF)', textColor: '#000000' }
  ];

  return (
  <nav>
    <StyledHeader>
      <StyledHamburgerButton setIsHamburgerOpen={setIsHamburgerOpen} isHamburgerOpen={isHamburgerOpen} />
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setIsHamburgerOpen(false)}>
        <StyledMenuButtonsDiv isHamburgerOpen={isHamburgerOpen}>
          { menuButtonsArr.map((prop, index) => 
            <StyledMenuButton
              key={index}
              name={prop.name}
              link={prop.link}
              index={index}
              setIsMenuClicked={setIsMenuClicked}
              setIsHamburgerOpen={setIsHamburgerOpen}
              bgColor={prop.bgColor}
              textColor={prop.textColor}
              />
            )
          }
        </StyledMenuButtonsDiv>
      </ClickAwayListener>
    </StyledHeader>
  </nav>
)
};

export default Header;