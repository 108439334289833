import styled from "@emotion/styled";

const StyledContactDiv = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledContactTitle = styled.h2`
  font-size: 4em;
  display: flex;
  flex-direction: row;
  margin: 0.9em 0 0.1em 0;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? `${(props.index + 1) * 100}ms` : `${600 + ((props.index + 1) * 30)}ms` };

  @media (max-width: 899px) {
    margin: 0 0 0.1em 0;
  }
`;

const StyledHeaderText = styled.h3`
  font-size: 2em;
  margin-bottom: 1em;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? `${(props.index + 1) * 100}ms` : `${600 + ((props.index + 1) * 30)}ms` };
`;

const StyledExternalLink = styled.div`
  background: linear-gradient(to right bottom, #98E0FF, #7ED8FF);
  margin-right: 0.5em;
  height: 1.8em;
  width: 1.8em;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 200ms;
`;

const StyledMailButton = styled.div`
  background: linear-gradient(to right bottom, #98E0FF, #7ED8FF);
  margin-right: 0.5em;
  height: 1.8em;
  width: 1.8em;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all ease-in-out 200ms;
`;

const StyledContactLink = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #000000;
  font-size: 30px;
  width: 6em;
  text-decoration: underline transparent;
  transition: all ease-in-out 200ms;
  margin-bottom: 0.5em;

  &:hover {
    text-decoration: underline #7ED8FF;
  }

  &:hover div {
    transform: translate(0, -3px);
    box-shadow: 1.5px 3px rgba(0, 0, 0, 0.2);
  }
`;

const StyledContactMail = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  text-decoration: underline transparent;
  align-items: center;
  color: #000000;
  font-size: 30px;
  width: 5em;
  padding: 0;
  transition: all ease-in-out 200ms;

  &:hover {
    text-decoration: underline #7ED8FF;
  }

  &:hover div {
    transform: translate(0, -3px);
    box-shadow: 1.5px 3px rgba(0, 0, 0, 0.2);
  }
`;

export { StyledContactDiv, StyledContactTitle, StyledExternalLink, StyledMailButton, StyledContactLink, StyledContactMail, StyledHeaderText };
