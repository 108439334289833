import styled from "@emotion/styled";

const ColorBlock = styled.div`
  width: 200px;
  height: ${props => `calc((100vh / 3.5) * ${props.height})`};
  background: ${props => `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${props.color})`};
  position: fixed;
  z-index: -99;
  bottom: 0;
  right: ${props => `${(props.fromRight * 200) + 100 }px`};
  opacity: 0;
  animation: 1.5s ease-in-out 1s infinite alternate fadeIn;
  animation-delay: ${props => (`${props.animate * 500}ms`)};

  @media (max-width: 1745px) {
    height: ${props => `calc((100vh / 4) * ${props.height})`};
    right: ${props => `${(props.fromRight * 200) - 35 }px`};
  }

  @media (max-width: 1560px) {
    right: ${props => `${(props.fromRight * 200) - 100 }px`};
  }

  @media (max-width: 1100px) {
    width: 150px;
    right: ${props => `calc(${(props.fromRight * 150) - 65}px)`};
  }

  @media (max-width: 768px) {
    width: calc(100vw / 3);
    right: ${props => `calc((100vw / 3) * ${props.fromRight} - (100vw / 3))`};
    height: ${props => `calc((100vh / 6) * ${props.height})`};
  }

  @media (max-width: 630px) {
    width: calc(100vw / 3);
    right: ${props => `calc((100vw / 3) * ${props.fromRight} - (100vw / 3))`};
  }
`;

const ScrollbarPlaceholder = styled.div`
  width: 15px;
  height: 100%;
`;

export { ColorBlock, ScrollbarPlaceholder };