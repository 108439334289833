import { StyledGiantBox } from "../../commons/Content.styles";
import projectsArr from "../../projects/projectsArr";
import { useEffect, useState } from 'react';
import { StyledProjectCard, StyledProjectTitleBox } from "./ProjectCard.styles";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { StyledGridContainer } from "./ProjectsPage.styles";


const ProjectsPage = ({ isMenuClicked, setIsMenuClicked, scrollTop, setIsModalOpen }) => {
  const [selectedProjectCat, setSelectedProjectCat] = useState("View All");
  const [categoryArr, setCategoryArr] = useState(projectsArr[1]);

  const [maxProjectCount, setMaxProjectCount] = useState(projectsArr[1].length - 1);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState();
  const [dateNow, setDateNow] = useState(Date.now());

  useEffect(() => {
    setCategoryArr([]);
    if (selectedProjectCat === "View All") {
      setCategoryArr(projectsArr[1]);
    }
    else {
      let tempArr = [];
      for (const entry of projectsArr[1]) {
        if (entry.projectCategory === selectedProjectCat) {
          tempArr.push(entry);
        }
      }
      setCategoryArr(tempArr);
      setMaxProjectCount(tempArr.length - 1);
    }
    setSelectedProjectIndex();
    setDateNow(Date.now());
  }, [selectedProjectCat]);

  const colors = ["rgba(126, 216, 255, 0.5)", "rgba(255, 140, 209, 0.5)", "rgba(255, 213, 104, 0.5)"];

  return (
    <StyledGiantBox isMenuClicked={isMenuClicked} justifyContent={'center'}>
      <StyledGridContainer container spacing={2}>
        <Grid xl={3} lg={4} md={6} xs={12} display="flex" justifyContent="center">
          <StyledProjectTitleBox isMenuClicked={isMenuClicked} selectedProjectCat={selectedProjectCat} setSelectedProjectCat={setSelectedProjectCat} setIsMenuClicked={setIsMenuClicked} />
        </Grid>
        { categoryArr.map((prop, index) =>
            <Grid xl={3} lg={4} md={6} xs={12} display="flex" justifyContent="center" key={`${prop.projectCategory}-${index}-${dateNow}`}>
              <StyledProjectCard
                name={prop.projectName}
                previewImage={prop.projectPreview}
                tags={prop.projectTags}
                category={prop.projectCategory}
                index={index}
                isMenuClicked={isMenuClicked}
                setIsMenuClicked={setIsMenuClicked}
                detailedImages={prop.projectImages}
                detailedVideo={prop.projectVideo}
                description={prop.projectDesc}
                link={prop.projectLink}
                selectedProjectIndex={selectedProjectIndex}
                setSelectedProjectIndex={setSelectedProjectIndex}
                maxProjectCount={maxProjectCount}
                setIsModalOpen={setIsModalOpen}
                scrollTop={scrollTop}
                color={colors[index % 3]}
                />
            </Grid>
          )
        } 
      </StyledGridContainer>
    </StyledGiantBox>
  );
}

export default ProjectsPage;