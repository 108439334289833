import { ArrowDropUpSharp } from "@mui/icons-material";
import Fade from '@mui/material/Fade';

const ScrollToTopButton = ({className, scrollTop}) => (
  <Fade in={scrollTop} timeout={200}>
    <button className={className} onClick={() => window.scroll({top: 0, left: 0, behavior: "smooth"})}>
      <ArrowDropUpSharp style={{fill: "#FFFFFF", fontSize: 35, verticalAlign: "middle"}} />
    </button>
  </Fade>
);

export default ScrollToTopButton;