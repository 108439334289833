//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './views/home/HomePage';
import ProjectsPage from './views/projects/ProjectsPage';
import ResumePage from './views/resume/ResumePage';
import AboutPage from './views/about/AboutPage';
import ContactPage from './views/contact/ContactPage'
import Header from './commons/Header';
import StyledScrollToTopButton from './commons/ScrollToTopButton.styles';
import WhiteBackdrop from './commons/Backdrop.styles';
import { StyledMainContainer } from './commons/Content.styles';
import { useState, useEffect } from 'react';

const App = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    const onScroll = () => window.scrollY > 230 ? setScrollTop(true) : setScrollTop(false);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <StyledMainContainer>
      <Router>
          <WhiteBackdrop
            isHamburgerOpen={isHamburgerOpen}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
          <StyledScrollToTopButton scrollTop={scrollTop} />
          <Header 
            setIsMenuClicked={setIsMenuClicked}
            isHamburgerOpen={isHamburgerOpen}
            setIsHamburgerOpen={setIsHamburgerOpen}
          />
        <div>
          <Routes>
            <Route
              path="/"
              element={<HomePage isMenuClicked={isMenuClicked} setIsMenuClicked={setIsMenuClicked} setIsHamburgerOpen={setIsHamburgerOpen} />}
            />
            <Route
              path="/projects"
              element={<ProjectsPage isMenuClicked={isMenuClicked} setIsMenuClicked={setIsMenuClicked} setIsModalOpen={setIsModalOpen} />}
            />
            <Route
              path="/resume"
              element={<ResumePage isMenuClicked={isMenuClicked} />}
            />
            <Route
              path="/about"
              element={<AboutPage isMenuClicked={isMenuClicked} /> }
            />
            <Route
              path="/contact"
              element={<ContactPage isMenuClicked={isMenuClicked} /> }
            />
          </Routes>
        </div>
      </Router>
    </StyledMainContainer>
  );
}

export default App;
