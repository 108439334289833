import ProjectDropdown from "./ProjectDropDown";
import styled from "@emotion/styled";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const StyledProjectDropdown = styled(ProjectDropdown)`
  max-width: 80%;
  border-radius: 0;
  text-align: center;
  align-items: center;
  font-size: 20px;
  height: 2em;
  border: none;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  background-color: #FF8CD1;
  background: linear-gradient(to right bottom, #FFA3DA, #FF8CD1);
  font-family: leagueSpartan;

  @media (max-width: 899px) {
    max-width: 100%;
  }

  @media (max-width: 520px) {
    min-width: none;
    width: 100%;
  }
`;

const StyledGridContainer = styled(Grid)`
  min-width: 100%;
`;

export { StyledProjectDropdown, StyledGridContainer };