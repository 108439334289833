import Grid from '@mui/material/Unstable_Grid2';
import useElementOnScreen from "../../commons/useElementOnScreen";
import { useState, useEffect } from 'react';

const AboutGridItem = ({className, children, index, move, sm, md, lg, xl, alwaysAppear}) => {
  // Test scroll animate
  const [showCard, setShowCard] = useState(false);
  const[ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.6
  })

  useEffect(() => {
    if ((isVisible && !showCard) || alwaysAppear) {
      setShowCard(true);
    }
  }, [isVisible, showCard, alwaysAppear]);

  return (
    <Grid
      className={showCard ? `${className} show-card` : `${className}`}
      ref={containerRef}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      {children}
    </Grid>
  );
}

export default AboutGridItem;