import StyledPageTitle from "../../commons/PageTitle.styles";
import { StyledWrapper } from "../../commons/Content.styles";
import { StyledProjectDropdown } from "./ProjectsPage.styles";

const ProjectTitleBox = ({ className, selectedProjectCat, setSelectedProjectCat, setIsMenuClicked, isMenuClicked }) => (
  <StyledWrapper>
    <div className={className}>
      <StyledPageTitle pageName={"Projects"} />
      <StyledProjectDropdown
        selectedProjectCat={selectedProjectCat}
        setSelectedProjectCat={setSelectedProjectCat}
        setIsMenuClicked={setIsMenuClicked}
        isMenuClicked={isMenuClicked}
        />
    </div>
  </StyledWrapper>
);

export default ProjectTitleBox;