import styled from "@emotion/styled";
import { MenuButton } from "../../commons/MenuButton";
import { Tooltip } from "@mui/material";

const StyledTitleName = styled.h1`
  //color: #FF8CD1;
  font-size: 5em;
  font-weight: 700;
  //text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? `${(props.index + 1) * 100}ms` : `${600 + ((props.index + 1) * 30)}ms` };

  @media (max-width: 450px) {
    font-size: 4em;
  }
`;

const StyledSubtitle = styled.h2`
  //color: #98E0FF;
  font-size: 3em;
  font-weight: 350;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? `${(props.index + 1) * 100}ms` : `${600 + ((props.index + 1) * 30)}ms` };

  @media (max-width: 450px) {
    font-size: 2em;
  }
`;

const StyledHomeDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15vh;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const StyledHomeLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  flex-wrap: wrap;
  position: relative;
`;

const StyledHomeMenuButton = styled(MenuButton)`
  color: black;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? `${(props.index + 1) * 100}ms` : `${600 + ((props.index + 1) * 30)}ms` };
  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  border: none;
  margin-right: 1em;
  margin-bottom: 1em;
  height: 2em;
  width: 12em;
  text-align: center;
  align-items: center;
  font-size: 26px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.3);
  padding-top: 4px;
  transition: all ease-in-out 200ms;

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 3px 4.5px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }

  
  @media (max-width: 899px) {
    width: 10em;
  }

  @media (max-width: 403px) {
    width: 80vw;
  }
`;

const StyledExternalLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2em;

  
  @media (max-width: 768px) {
    margin-top: 1em;
  }
`;

const StyledExternalLink = styled.a`
  background: linear-gradient(to right bottom, #98E0FF, #7ED8FF);
  margin-right: 0.5em;
  height: 2.5em;
  width: 2.5em;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 200ms;

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 1.5px 3px rgba(0, 0, 0, 0.2);
  }
`;

const StyledMailButton = styled.button`
  background: linear-gradient(to right bottom, #98E0FF, #7ED8FF);
  margin-right: 0.5em;
  height: 2.5em;
  width: 2.5em;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all ease-in-out 200ms;

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 1.5px 3px rgba(0, 0, 0, 0.2);
  }
`;

const StyledToolTip = styled(Tooltip)`
  border-radius: 0 !important;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? `${100 + (props.index + 1) * 30}ms` : `${600 + ((props.index + 1) * 30)}ms` };

  &::before {
    border-radius: 0 !important;
  }
`;

export {
  StyledTitleName,
  StyledSubtitle,
  StyledHomeDiv,
  StyledHomeLinks,
  StyledHomeMenuButton,
  StyledExternalLink,
  StyledMailButton,
  StyledToolTip,
  StyledExternalLinksDiv
};