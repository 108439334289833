// Chatbot
import ChatbotPreview from "./preview-images/chatbot.png";
import ChatbotLogin from "./web-dev/chatbot/chatbot_login.png";

// KodeGuru
import KodeGuruSitePreview from "./preview-images/kodeguru_login.png";
import KodeGuruStats from "./web-dev/kodeguru/kodeguru_stats.png";
// import KodeGuruHome from "./web-dev/kodeguru/kodeguru_home.png";
import KodeGuruResult from "./web-dev/kodeguru/kodeguru_result.png";
import KodeGuruQuiz from "./web-dev/kodeguru/kodeguru_quiz.png";
import KodeGuruPlay from "./web-dev/kodeguru/kodeguru_play.png";

// KodeGuru Sprites
// import KodeSpriteStar from "./web-dev/kodesprites/kodesprites_star.png";
// import KodeSpriteCool from "./web-dev/kodesprites/kodesprites_cool.png";
// import KodeSpriteFlag from "./web-dev/kodesprites/kodesprites_flag.png";
// import KodeSpriteCamp from "./web-dev/kodesprites/kodesprites_camp.png";
// import KodeSpriteConcept from "./web-dev/kodesprites/kody_concept.png";
// import KodeSpriteMountain from "./web-dev/kodesprites/kodesprites_mountain.png";
// import KodeSpriteLogo from "./web-dev/kodesprites/kodesprites_logo.png";

// Mech Capstone
import MechOpen from "./web-dev/mech/mech_teams.png";
import MechClosed from "./web-dev/mech/mech_teams_closed.png";

// IDE
import IDEForm from "./web-dev/ide/ide_form.png";
import IDEEmbedPdf from "./web-dev/ide/ide_pdf_embed.png";
import IDEEmbedH5p from "./web-dev/ide/ide_h5p_embed.png";

// Crescento
import CrescentoHome from "./web-dev/crescento/crescento_home.png";
import CrescentoCatalog from "./web-dev/crescento/crescento_catalog.png";
import CrescentoOffcanvas from "./web-dev/crescento/crescento_offcanvas.png";
import CrescentoCart from "./web-dev/crescento/crescento_cart.png";
import CrescentoStripe from "./web-dev/crescento/crescento_stripe.png";
import CrescentoReceipt from "./web-dev/crescento/crescento_receipt.png";
import CrescentoOrderHistory from "./web-dev/crescento/crescento_order_history.png";

// Empire Donuts
import EmpirePreview from "./web-dev/empiredonuts/empiredonuts_home_crop.png";
import EmpireHome from "./web-dev/empiredonuts/empiredonuts_home.png";
import EmpireOrder from "./web-dev/empiredonuts/empiredonuts_order.png";
import EmpireCart from "./web-dev/empiredonuts/empiredonuts_cart.png";
import EmpireDetails from "./web-dev/empiredonuts/empiredonuts_details.png";

// Pippin Orchards
import PippinSC1 from "./web-dev/pippin/pippin_1.png";
import PippinSC2 from "./web-dev/pippin/pippin_2.png";

// Video thumbnail format:  "https://img.youtube.com/vi/{id}/maxresdefault.jpg"
// Video embed format:      "https://www.youtube.com/embed/{id}"

/*
const projectsArr = [
  ["View All", "Web Development", "UX/UI & Graphic Design", "Game Development", "3D Modelling & Animation"],
  [
    {
      projectName: "OpenAI Chatbot",
      projectCategory: "Web Development",
      projectTags: ["Next.js", "MongoDB", "OpenAI"],
      projectPreview: ChatbotPreview,
      projectDesc: null,
      projectImages: [["image", ChatbotPreview], ["image", ChatbotLogin], ["video", "https://img.youtube.com/vi/ZT0aboXvS4k/maxresdefault.jpg" ,"https://www.youtube.com/embed/ZT0aboXvS4k"]],
      projectVideo: "https://youtu.be/huSWRpQWOw4?si=AoY0ghZzo05vdm_2",
      projectLink: null
    },
    {
      projectName: "Crescento Apothecary Storefront",
      projectCategory: "Web Development",
      projectTags: ["PHP", "CSS", "MySQL"],
      projectPreview: CrescentoHome,
      projectDesc: null,
      projectImages: [["image", CrescentoHome], ["image", CrescentoCatalog], ["image", CrescentoOffcanvas], ["image", CrescentoCart], ["image", CrescentoStripe], ["image", CrescentoReceipt], ["image", CrescentoOrderHistory]],
      projectLink: null
    },
    {
      projectName: `Capstone Project "KodeGuru"`,
      projectCategory: "Web Development",
      projectTags: ["JavaScript", "jQuery", "API"],
      projectPreview: KodeGuruSitePreview,
      projectDesc: null,
      projectImages: [["image", KodeGuruSitePreview], ["image", KodeGuruPlay], ["image", KodeGuruStats], ["image", KodeGuruQuiz], ["image", KodeGuruResult]],
      projectLink: null
    },
    {
      projectName: "KodeGuru Logo and SVG Assets",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Figma", "Clip Studio Paint"],
      projectPreview: KodeSpriteFlag,
      projectDesc: null,
      projectImages: [["image", KodeSpriteStar], ["image", KodeSpriteCool], ["image", KodeSpriteFlag], ["image", KodeSpriteLogo], ["image", KodeSpriteCamp], ["image", KodeSpriteMountain], ["image", KodeSpriteConcept]],
      projectLink: null
    },
    {
      projectName: "Interdisciplinary Education Festival 2024 Website Upload Feature",
      projectCategory: "Web Development",
      projectTags: ["PHP", "JavaScript", "MySQL"],
      projectPreview: IDEEmbedPdf,
      projectDesc: null,
      projectImages: [["image", IDEEmbedPdf], ["image", IDEForm], ["image", IDEEmbedH5p], ["video", "https://img.youtube.com/vi/huSWRpQWOw4/maxresdefault.jpg" ,"https://www.youtube.com/embed/huSWRpQWOw4"]],
      projectLink: null
    },
    {
      projectName: "Empire Donuts Website Redesign",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe XD"],
      projectPreview: EmpirePreview,
      projectDesc: null,
      projectImages: [["image", EmpireHome],["image", EmpireOrder],["image", EmpireDetails],["image", EmpireCart]],
      projectLink: null
    },
    {
      projectName: "Camosun Mechanical Engineering August 2024 Capstone Website",
      projectCategory: "Web Development",
      projectTags: ["Vite", "TypeScript"],
      projectPreview: MechOpen,
      projectDesc: null,
      projectImages: [["image", MechOpen], ["image", MechClosed]],
      projectLink: "https://capstone.camosun.bc.ca/meng/dist/"
    },
    {
      projectName: "Money Manager App Journey Map",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe InDesign", "Adobe Illustrator"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Pippin Orchards Mobile App",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe XD"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Pippin Orchards Logo",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe Illustrator", "Clip Studio Paint"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "FTL Pizza Website for iPad (Vertical)",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe XD", "Adobe Photoshop"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "FTL Pizza Logo",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe Illustrator", "Clip Studio Paint"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Pandemonirun",
      projectCategory: "Game Development",
      projectTags: ["Unity", "C#"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Tetris Remake",
      projectCategory: "Game Development",
      projectTags: ["Microsoft Visual Studio", "C++"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "VR Sorting",
      projectCategory: "Game Development",
      projectTags: ["Unity", "C#", "VR"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Camosun Buildings Recreated in Unreal",
      projectCategory: "Game Development",
      projectTags: ["Unreal Engine", "Nanite"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Quadcopter Showcase",
      projectCategory: "3D Modelling & Animation",
      projectTags: ["Autodesk Maya", "Adobe Premiere"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Bistro Fleurir",
      projectCategory: "3D Modelling & Animation",
      projectTags: ["Autodesk Maya", "Adobe Illustrator", "Bifrost"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Barnbie Toy Tractor",
      projectCategory: "3D Modelling & Animation",
      projectTags: ["Autodesk Maya", "Adobe Illustrator", "Procreate"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
    {
      projectName: "Duck in a Chest",
      projectCategory: "3D Modelling & Animation",
      projectTags: ["Autodesk Maya"],
      projectPreview: null,
      projectDesc: null,
      projectImages: [null],
      projectLink: null
    },
  ]
];
*/

// Video thumbnail format:  "https://img.youtube.com/vi/{id}/maxresdefault.jpg"
// Video embed format:      "https://www.youtube.com/embed/{id}"

// Temporary array only!
const projectsArr = [
  ["View All", "Web Development", "UX/UI & Graphic Design"],
  [
    {
      projectName: "OpenAI Chatbot",
      projectCategory: "Web Development",
      projectTags: ["Next.js", "MongoDB", "OpenAI"],
      projectPreview: ChatbotPreview,
      projectDesc: [
        `This project was created as lab assignment for my web applications course in my 2nd year in the IMD program. We were tasked to create an OpenAI chatbot that logs the user’s prompts. We also needed to create a login page that will only allow certain credentials (as the OpenAI token was provided by the instructor).`,
        `The prompts and the answers are logged in MongoDB using Node.js endpoints. The user can also purge the log if they wish. When the user logs back in after previously having prompted the chatbot, all saved prompts are resent to OpenAI so it has context of the previous conversation and can continue an old topic.`,
        `User credentials were manually added into the database since a registration function was not required. I used Redux to keep track of which user is logged in.`,
        `As an added challenge, I added a speech-to-text function to my chatbot. I was unaware that OpenAI already has an API for that and instead I ended up installing a react package to handle it.`,
        `As for the design, I opted to do something simple as I had limited time to work on this. I wanted to recreate a pixel-y aesthetic to lean into the feeling of talking to a bot.`,
        `Overall, this project was quite fun and interesting! I had not used ChatGPT before this nor have I used it again since, but it was useful learning the ins and outs of it.`
      ],
      projectImages: [["image", ChatbotPreview], ["image", ChatbotLogin], ["video", "https://img.youtube.com/vi/ZT0aboXvS4k/maxresdefault.jpg" ,"https://www.youtube.com/embed/ZT0aboXvS4k"]],
      projectVideo: "https://youtu.be/huSWRpQWOw4?si=AoY0ghZzo05vdm_2",
      projectLink: null
    },
    {
      projectName: "Crescento Apothecary Storefront",
      projectCategory: "Web Development",
      projectTags: ["PHP", "CSS", "MySQL"],
      projectPreview: CrescentoHome,
      projectDesc: [
        `In lieu of co-op, I decided to take a course in the summer of my 1st year in the IMD program. For the whole 2 months, we were tasked to create a website for a store using PHP and MySQL. My team of 3 decided to create a website that sells candles and other relaxing scented items. My main responsibility was to write and fix the PHP side of things, but I also helped with the CSS and setting up of the database from time to time.`,
        `Before we delved into the development phase, I wrote most of the business requirements document. I also set up a Gantt chart for my team to keep track of our progress.`,
        `On top of being able to “purchase” items, we also had to implement an admin side where admins can add new products without them having to manually add it into the database.`,
        `The hardest part of the project was creating a receipt of an order. Since we were unable to send it as an email confirmation as we were on a free server, we had to make it into a text file that saves into a receipt folder outside of the project folder out of reach of users. We did not have permissions to write outside of our main folder, so I had to research how to do it (with the blessing of the instructor).`
      ],
      projectImages: [["image", CrescentoHome], ["image", CrescentoCatalog], ["image", CrescentoOffcanvas], ["image", CrescentoCart], ["image", CrescentoStripe], ["image", CrescentoReceipt], ["image", CrescentoOrderHistory]],
      projectLink: null
    },
    {
      projectName: "Interdisciplinary Education Festival 2024 Website Upload Feature",
      projectCategory: "Web Development",
      projectTags: ["PHP", "JavaScript", "MySQL"],
      projectPreview: IDEEmbedPdf,
      projectDesc: [
        `This was the first major project I had where more than 20 people had to work together. It was also the first one where it was a continuing project instead of a new one. The IDE Festival is a yearly event where the School of Health and Human Services students at Camosun present their projects and findings to the public. It is then the Interactive Media Development students’ job to create a website for the event and to promote it.`,
        `I relegated myself to back-end development team as the previous year of students built the site in PHP, and I already had a decent amount of experience working with PHP and MySQL from working on Crescento Apothecary. I was responsible for creating an upload form for the HHS students and ensuring that 6 types of files can be uploaded/embedded (images, video embeds, H5P embeds, PDFs, HTML, and plain text) and display properly on the entry page.`,
        `I worked with the school IT to get the site deployed to the school server. I also opted to write the documentation for the site for this course’s final project since we did not have any documentation to go on from the previous students, and I wanted the next set of students to have an easier time parsing through the code.`
      ],
      projectImages: [["image", IDEEmbedPdf], ["image", IDEForm], ["image", IDEEmbedH5p], ["video", "https://img.youtube.com/vi/huSWRpQWOw4/maxresdefault.jpg" ,"https://www.youtube.com/embed/huSWRpQWOw4"]],
      projectLink: "https://ide.camosun.ca/"
    },
    {
      projectName: "Empire Donuts Website Redesign",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe XD"],
      projectPreview: EmpirePreview,
      projectDesc: [
        `For our final project for our UX/UI course in the 2nd year of the IMD program, we were given a list of local donut shops and was tasked to redesign it to make it more usable for users. I picked Empire Donuts to redesign as it is one of Victoria’s local gems.`,
        `It was hard to garner the theming and the brand just looking at the current Empire Donuts website, so I decided to look at the shop locations’ interiors. The shops had a very retro feel to them, themed like something from the 50’s to 60’s. One of the locations had a menu board that was like a theatre board. Their logo also reminded me of old title cards. I decided to lean into the retro feeling while moving the content around to where they would make most sense.`,
        `Since there was limited time for the project, we only had to redesign the home page, the products page, and the cart.`,
      ],
      projectImages: [["image", EmpireHome],["image", EmpireOrder],["image", EmpireDetails],["image", EmpireCart], ["video", "https://img.youtube.com/vi/vONLfmaVK7Y/maxresdefault.jpg", "https://www.youtube.com/embed/vONLfmaVK7Y"]],
      projectLink: null
    },
    {
      projectName: "KodeGuru Web App",
      projectCategory: "Web Development",
      projectTags: ["JavaScript", "jQuery", "API"],
      projectPreview: KodeGuruSitePreview,
      projectDesc: [
        `For our Capstone project, my team of 3 matched with Camosun’s Brandon Devnich to work on his web application idea called Kode.GURU. It is a web application intended to help his students review programming concepts that he teaches in his classes to improve the long-term retention of the concepts.`,
        `Our challenge was to make a web app that was fun and incorporates gamification, like certain language-learning apps. I was responsible for writing documents, creating promotional items like brochures and posters, and many of the features such as the course picker, the stats page, and XP and streak bars. I ensured that the different views worked together since it is a single page application that updates via jQuery and JavaScript as requested by the sponsor.`,
        `On top of the functionality, I also created the SVG assets entirely in Figma such as the backgrounds, icons, and most especially, the mascot Kody.`
      ],
      projectImages: [["image", KodeGuruSitePreview], ["image", KodeGuruPlay], ["image", KodeGuruStats], ["image", KodeGuruQuiz], ["image", KodeGuruResult]],
      projectLink: null
    },
    {
      projectName: "Camosun Mechanical Engineering August 2024 Capstone Website",
      projectCategory: "Web Development",
      projectTags: ["Vite", "TypeScript"],
      projectPreview: MechOpen,
      projectDesc: [
        `During our Capstone term, we also had to join a committee to prepare the Capstone Symposium for the public. I joined the committee for creating the Mechanical Engineering website.`,
        `In our committee of 4 people, I was tasked with creating cards to introduce the engineering teams and their projects. I created the cards out of Bootstrap accordions which I customized to fit with our theme. I also set up an array that holds all the teams’ information so that the other members can easily get bits and pieces from one place to use as props. Other than that, I fixed styling problems around the site as I saw them.`
      ],
      projectImages: [["image", MechOpen], ["image", MechClosed]],
      projectLink: "https://capstone.camosun.bc.ca/meng/dist/"
    },
    {
      projectName: "Pippin Orchards Mobile App",
      projectCategory: "UX/UI & Graphic Design",
      projectTags: ["Adobe XD"],
      projectPreview: PippinSC1,
      projectDesc: [
        `For a project in UX/UI course in the 1st year of IMD, we were given a list of apples and their best use and was tasked with creating a mobile app prototype using that list. I decided to create a mobile app for a fictional orchard called Pippin Orchards where people can order different types of apples for pickup.`,
        `I wanted to convey a sense of a reliable family-run business that has been around for a long time, so I chose vintage beige, yellow, and brown colours for the theme. The logo I made is of a barn house within an apple basket to further reinforce the local family-run business feeling.`,
        `What I’m most proud of is the apple directory section where users can click on images of apples overlaid on top of a faint image of an apple tree, almost as if they’re picking the apples themselves.`
      ],
      projectImages: [["image", PippinSC1], ["image", PippinSC2], ["video", "https://img.youtube.com/vi/UPH_ZPsamvc/maxresdefault.jpg", "https://www.youtube.com/embed/UPH_ZPsamvc"]],
      projectLink: null
    },
  ]
];

export default projectsArr;