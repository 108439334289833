import ScrollToTopButton from "./ScrollToTopButton";
import styled from "@emotion/styled";

const StyledScrollToTopButton = styled(ScrollToTopButton)`
  position: fixed;
  right: 1em;
  bottom: 1em;
  z-index: 98;
  background: linear-gradient(to right bottom, #333333, #000000);
  margin-right: 0.5em;
  height: 3em;
  width: 3em;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

export default StyledScrollToTopButton;