import { StyledGiantBox } from "../../commons/Content.styles";
import { StyledContactDiv, StyledContactTitle, StyledExternalLink, StyledMailButton, StyledContactLink, StyledContactMail, StyledHeaderText } from "./ContactPage.styles";
import { ColorBlock } from "../../commons/Decorations.styles";
import { LinkedIn, GitHub, EmailSharp } from '@mui/icons-material';
import { StyledToolTip } from "../home/HomePage.styles";
import { Fade } from "@mui/material";
import { useState } from "react";

const ContactPage = ({ isMenuClicked }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isEmailCopied, setIsEmailCopied] = useState(false);

  const outLinksArr = [
    { name: 'LinkedIn', link: 'https://www.linkedin.com/in/alyssa-villanueva-9542a72bb/', icon: <LinkedIn style={{fill: "#FFFFFF", fontSize: 45}} />, description: "Go to Alyssa's LinkedIn profile"},
    { name: 'GitHub', link: 'https://github.com/alyssav0831', icon: <GitHub style={{fill: "#FFFFFF", fontSize: 40, verticalAlign: "middle"}} />, description: "Go to Alyssa's GitHub profile"},
  ];

  const email = { name: 'Email', email: 'alyssav0831@yahoo.com', icon: <EmailSharp style={{fill: "#FFFFFF", fontSize: 40, verticalAlign: "middle"}} />, description: 'Click to copy "alyssav0831@yahoo.com" to clipboard'};
  
  const handleEmailClick = () => {
    handleTooltipOpen();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email.email);
      setIsEmailCopied(true);
    }
    else {
      window.clipboardData.setData("Text", email.email);
      setIsEmailCopied(true);
    }

    setTimeout(() => {
      handleTooltipClose();
    }, 3000);

    setTimeout(() => {
      setIsEmailCopied(false);
    }, 3500);
  }

  const handleTooltipClose = () => {
    if (!isEmailCopied) {
      setTooltipOpen(false);
    }
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <StyledGiantBox isMenuClicked={isMenuClicked}>
      <StyledContactDiv>
        <StyledContactTitle isMenuClicked={isMenuClicked} index={0}>Contact</StyledContactTitle>
        <StyledHeaderText fontSize={2} isMenuClicked={isMenuClicked} index={1}>Interested? Let's get in touch!</StyledHeaderText>
        {
            outLinksArr.map((prop, index) => (
              <StyledToolTip
                title={prop.description}
                key={`link-${index}`}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
                componentsProps={{ tooltip: { sx: { borderRadius: 0, background: "#000000", color: "#FFFFFF", fontSize: 12, textAlign: "center" } }, arrow: { sx: { color: "#000000"}} }}
                placement="right"
                isMenuClicked={isMenuClicked}
                index={index + 2}
                arrow
              >
                <StyledContactLink
                  href={prop.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledExternalLink>
                    {prop.icon}
                  </StyledExternalLink>
                  <span>{prop.name}</span>
                </StyledContactLink>
              </StyledToolTip>
            ))
          }
          <StyledToolTip
                title={isEmailCopied ? 'Copied "alyssav0831@yahoo.com" to clipboard!' : email.description}
                sx={{borderRadius: 0}}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
                componentsProps={{ tooltip: { sx: { borderRadius: 0, background: "#000000", color: "#FFFFFF", fontSize: 12, textAlign: "center" } }, arrow: { sx: { color: "#000000"}} }}
                open={tooltipOpen}
                placement="right"
                isMenuClicked={isMenuClicked}
                index={4}
                arrow
              >
            <StyledContactMail
              onClick={handleEmailClick}
              onMouseEnter={handleTooltipOpen}
              onMouseLeave={handleTooltipClose}
            >
              <StyledMailButton>
                {email.icon}
              </StyledMailButton>
              <span>{email.name}</span>
            </StyledContactMail>
          </StyledToolTip>
      </StyledContactDiv>
      <ColorBlock height={1} color={'#FF8CD1'} fromRight={3} animate={1} />
      <ColorBlock height={2} color={'#7ED8FF'} fromRight={2} animate={3} />
      <ColorBlock height={3} color={'#FFD568'} fromRight={1} animate={2} />
    </StyledGiantBox>
  )
};

export default ContactPage;