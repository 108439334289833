import { IconButton } from "@mui/material";
import { useState, useEffect, useRef } from "react";

const DetailedImagesThumbButton = ({className, children, name, index, handleClickSpecific, checkMediaType, setClickedThumb, prop, activeStep}) => {
  const [mediaType, setMediaType] = useState("image");
  useEffect(() => {
    setMediaType(prop[0]);
  }, [prop]);

  const thumbRef = useRef();
  useEffect(() => {
    if (index === activeStep) {
      setClickedThumb(thumbRef.current);
    }
  }, [activeStep, index, setClickedThumb, prop]);
  
  return (
    <IconButton
      className={mediaType === "video" ? `${className} videoIcon` : className}
      id={`${name}-image-${index}`}
      ref={thumbRef}
      index={index}
      onClick={(e) => { 
        handleClickSpecific(index);
        checkMediaType(prop[0]);
        setClickedThumb(e.currentTarget);
        }
      }
    >
      {children}
    </IconButton>
  )
}

export default DetailedImagesThumbButton;