import { Link } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
// import { Slide } from '@mui/material';

const MenuButton = ({name, link, index, className, setIsMenuClicked, setIsHamburgerOpen}) => {
  //let checked = true;
  // Do animation here?
  return (
    <Link to={link}>
      <button className={className} onClick={() => { setIsMenuClicked(true); setIsHamburgerOpen(false); }}>{name}</button>
    </Link>
  );
}

const HamburgerButton = ({ className, setIsHamburgerOpen, isHamburgerOpen }) => (
  <button className={className} onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}><Menu /></button>
);


/* <Slide direction='left' in={checked} style={{ transitionDelay: checked ? `${1000 * index}ms` : '0ms' }} mountOnEnter unmountOnExit>
<Link to={link}>
  <button className={className} onClick={() => setIsMenuClicked(true)}>{name}</button>
</Link>
</Slide> */

export { MenuButton, HamburgerButton };