import styled from "@emotion/styled";
import Backdrop from "./Backdrop";

const WhiteBackdrop = styled(Backdrop)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: end;
  background: rgba(255, 255, 255, 0.75);
  z-index: 99;
`;

export default WhiteBackdrop;