import MainContainer from "./Content";
import styled from "@emotion/styled";

const StyledMainContainer = styled(MainContainer)`
  width: 80vw;
  margin: 10vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledGiantBox = styled(MainContainer)`
  position: relative;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  margin: 2em 0;
  min-width: 100%;
  min-height: 50vh;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => props.isMenuClicked ? '100ms' : '600ms' };

  justify-content: ${props => props.justifyContent};
`;

const StyledWrapper = styled(MainContainer)`
  position: relative;
  min-height: 300px;
  min-width: 300px;
  max-height: 300px;
  padding: 0.5em;
  align-content: center;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: 200ms;

  @media (max-width: 899px) {
    min-height: 170px;
  }

  @media (max-width: 520px) {
    min-width: none;
  }
`;

// const StyledBackgroundDiv = styled.div`
//   position: absolute;
//   z-index: -99;
//   min-height: 100vh;
//   max-height: 100vh;
//   min-width: 100vw;
//   max-width: 100vw;
// `;

export { StyledMainContainer, StyledGiantBox, StyledWrapper };